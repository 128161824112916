var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-sheet",
    { staticClass: "account", attrs: { width: "100%" } },
    [
      _c(
        "v-tabs",
        {
          staticClass: "settings-tabs",
          class: { " pt-5": _vm.$vuetify.breakpoint.mdAndUp },
          attrs: {
            "center-active": !_vm.$vuetify.breakpoint.mdAndUp,
            centered: !_vm.$vuetify.breakpoint.mdAndUp,
            vertical: _vm.$vuetify.breakpoint.mdAndUp,
            "show-arrows": "",
          },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "mt-3",
              class: {
                column: _vm.$vuetify.breakpoint.mdAndUp,
                "d-flex": !_vm.$vuetify.breakpoint.mdAndUp,
              },
              staticStyle: {
                "max-height": "calc(100vh - 84px)",
                "overflow-y": "auto",
              },
            },
            [
              _c(
                "v-tab",
                { staticClass: "justify-start" },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("fal fa-id-card"),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("profileLabel")))]),
                ],
                1
              ),
              _c(
                "v-tab",
                { staticClass: "justify-start" },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("fal fa-school"),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("mySchoolLabel")))]),
                ],
                1
              ),
              _c(
                "v-tab",
                { staticClass: "justify-start" },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("fal fa-user-shield"),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("securityLabel")))]),
                ],
                1
              ),
              _vm.userMode === "T"
                ? _c(
                    "v-tab",
                    { staticClass: "justify-start" },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("fab fa-google"),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.$t("googleLabel")))]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-tab",
                { staticClass: "justify-start" },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("fal fa-credit-card"),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("billingLabel")))]),
                ],
                1
              ),
              _c(
                "v-tab",
                { staticClass: "justify-start" },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("fal fa-bell"),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("notificationsLabel")))]),
                ],
                1
              ),
              _c(
                "v-tab",
                { staticClass: "justify-start" },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("fal fa-clock"),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("sessionLabel")))]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-tab-item", [
            _c(
              "div",
              { staticClass: "d-flex justify-center" },
              [
                _c(
                  "v-form",
                  { ref: "profileForm" },
                  [
                    _c(
                      "v-card",
                      {
                        attrs: {
                          flat: "",
                          width: "100%",
                          "max-width": "800px",
                        },
                      },
                      [
                        _c(
                          "perfect-scrollbar",
                          { style: { "max-height": _vm.scrollHeight } },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "px-0 py-0" },
                              [
                                _c(
                                  "v-container",
                                  { staticClass: "px-10" },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "d-flex justify-center",
                                            attrs: { cols: "12", md: "3" },
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass: "focus-border",
                                                attrs: {
                                                  outlined: "",
                                                  width: "150px",
                                                  height: "140px",
                                                  loading: _vm.isChoosingPhoto,
                                                  tabindex: "0",
                                                  role: "button",
                                                  "aria-label":
                                                    _vm.$t("editPhotoLabel"),
                                                },
                                                on: {
                                                  keydown: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.choosePhoto.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-card-text",
                                                  { staticClass: "pa-2" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "title-on-border",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("photoLabel")
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "text-center",
                                                    staticStyle: {
                                                      height: "120px",
                                                      cursor: "pointer",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.choosePhoto.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm.hasPhotoUrl
                                                      ? _c("img", {
                                                          staticStyle: {
                                                            width: "110px",
                                                            height: "110px",
                                                            "object-fit":
                                                              "cover",
                                                          },
                                                          attrs: {
                                                            src: _vm.photoUrl,
                                                            role: "presentation",
                                                          },
                                                        })
                                                      : _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              size: "110px",
                                                              color: "grey",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " fas fa-user "
                                                            ),
                                                          ]
                                                        ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "9" } },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        outlined: "",
                                                        "hide-details": "auto",
                                                        label:
                                                          _vm.$t(
                                                            "firstNameLabel"
                                                          ),
                                                      },
                                                      model: {
                                                        value: _vm.firstName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.firstName = $$v
                                                        },
                                                        expression: "firstName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        outlined: "",
                                                        "hide-details": "auto",
                                                        label:
                                                          _vm.$t(
                                                            "lastNameLabel"
                                                          ),
                                                      },
                                                      model: {
                                                        value: _vm.lastName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.lastName = $$v
                                                        },
                                                        expression: "lastName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                outlined: "",
                                                "hide-details": "auto",
                                                label:
                                                  _vm.$t("displayNameLabel"),
                                              },
                                              model: {
                                                value: _vm.displayName,
                                                callback: function ($$v) {
                                                  _vm.displayName = $$v
                                                },
                                                expression: "displayName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                outlined: "",
                                                "hide-details": "auto",
                                                label:
                                                  _vm.$t("emailAddressLabel") +
                                                  "*",
                                                "aria-required": "true",
                                                "error-messages":
                                                  _vm.emailErrors,
                                                rules: _vm.emailAddressRule,
                                              },
                                              model: {
                                                value: _vm.emailAddress,
                                                callback: function ($$v) {
                                                  _vm.emailAddress = $$v
                                                },
                                                expression: "emailAddress",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-expand-transition",
                                          [
                                            _vm.emailAddress !=
                                            _vm.oldEmailAddress
                                              ? _c(
                                                  "v-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        outlined: "",
                                                        "hide-details": "auto",
                                                        type: "password",
                                                        label:
                                                          _vm.$t(
                                                            "currentPasswordLabel"
                                                          ) + "*",
                                                        "aria-required": "true",
                                                        "error-messages":
                                                          _vm.passwordErrors,
                                                        rules: [
                                                          (v) =>
                                                            !!v ||
                                                            _vm.$t(
                                                              "requiredMsg2"
                                                            ),
                                                        ],
                                                      },
                                                      model: {
                                                        value: _vm.userPassword,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.userPassword = $$v
                                                        },
                                                        expression:
                                                          "userPassword",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "justify-end px-10 pt-3" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  width: "175px",
                                  color: "error",
                                  disabled: _vm.isNotDirty,
                                },
                                on: { click: _vm.saveProfile },
                              },
                              [_vm._v(_vm._s(_vm.$t("saveLabel")))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("v-tab-item", [
            _c(
              "div",
              { staticClass: "d-flex justify-center" },
              [
                _vm.isConnectedToSchool
                  ? _c(
                      "v-card",
                      {
                        attrs: {
                          flat: "",
                          width: "100%",
                          "max-width": "800px",
                        },
                      },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "px-0 py-0" },
                          [
                            _c(
                              "v-container",
                              { staticClass: "px-10" },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass: "mt-2",
                                            attrs: {
                                              outlined: "",
                                              width: "100%",
                                              "max-width": "800px",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              { staticClass: "pa-0" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "title-on-border",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "schoolNameLabel"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-list",
                                                  [
                                                    _c(
                                                      "v-list-item",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-inline-block",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.schoolName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-list-item-action",
                                                          [
                                                            _c(
                                                              "v-menu",
                                                              {
                                                                attrs: {
                                                                  "offset-y":
                                                                    "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                          attrs,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        plain:
                                                                                          "",
                                                                                      },
                                                                                  },
                                                                                  "v-btn",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "fal fa-info-circle"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    2604611401
                                                                  ),
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list",
                                                                  [
                                                                    _c(
                                                                      "v-list-item",
                                                                      {
                                                                        staticClass:
                                                                          "mr-3",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-list-item-title",
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "schoolIdLabel"
                                                                                    )
                                                                                  ) +
                                                                                    ":"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "pl-1",
                                                                                attrs:
                                                                                  {
                                                                                    id: "account-schoolId",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.schoolId
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-list-item-action",
                                                                          [
                                                                            _c(
                                                                              "pb-btn",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    "x-small":
                                                                                      "",
                                                                                    plain:
                                                                                      "",
                                                                                    label:
                                                                                      _vm.$t(
                                                                                        "copyClipboardLabel"
                                                                                      ),
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.copyToClipboard(
                                                                                        _vm.schoolId
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "fal fa-copy"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass: "mt-2",
                                            attrs: {
                                              outlined: "",
                                              width: "100%",
                                              "max-width": "800px",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              { staticClass: "pa-0" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "title-on-border",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "schoolDistrictLabel"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-list",
                                                  [
                                                    _c(
                                                      "v-list-item",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-inline-block",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.schoolDistrict
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-list-item-action",
                                                          [
                                                            _c(
                                                              "v-menu",
                                                              {
                                                                attrs: {
                                                                  "offset-y":
                                                                    "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                          attrs,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        plain:
                                                                                          "",
                                                                                      },
                                                                                  },
                                                                                  "v-btn",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "fal fa-info-circle"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    2604611401
                                                                  ),
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list",
                                                                  [
                                                                    _c(
                                                                      "v-list-item",
                                                                      {
                                                                        staticClass:
                                                                          "mr-3",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-list-item-title",
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "districtIdLabel"
                                                                                    )
                                                                                  ) +
                                                                                    ":"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "pl-1",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.districtId
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-list-item-action",
                                                                          [
                                                                            _c(
                                                                              "pb-btn",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    "x-small":
                                                                                      "",
                                                                                    plain:
                                                                                      "",
                                                                                    label:
                                                                                      _vm.$t(
                                                                                        "copyClipboardLabel"
                                                                                      ),
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.copyToClipboard(
                                                                                        _vm.districtId
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "fal fa-copy"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "v-card",
                      {
                        attrs: {
                          flat: "",
                          width: "100%",
                          "max-width": "800px",
                        },
                      },
                      [
                        _c(
                          "v-form",
                          { ref: "mySchoolForm" },
                          [
                            _c(
                              "perfect-scrollbar",
                              { style: { "max-height": _vm.scrollHeight } },
                              [
                                _c(
                                  "v-card-text",
                                  { staticClass: "px-0 py-0" },
                                  [
                                    _c(
                                      "v-container",
                                      { staticClass: "px-10" },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    outlined: "",
                                                    "hide-details": "auto",
                                                    label:
                                                      _vm.$t("schoolNameLabel"),
                                                  },
                                                  model: {
                                                    value: _vm.schoolName,
                                                    callback: function ($$v) {
                                                      _vm.schoolName = $$v
                                                    },
                                                    expression: "schoolName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    outlined: "",
                                                    "hide-details": "auto",
                                                    label: _vm.$t(
                                                      "schoolDistrictLabel"
                                                    ),
                                                  },
                                                  model: {
                                                    value: _vm.schoolDistrict,
                                                    callback: function ($$v) {
                                                      _vm.schoolDistrict = $$v
                                                    },
                                                    expression:
                                                      "schoolDistrict",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "justify-end px-10 pt-3" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  width: "175px",
                                  color: "error",
                                  disabled: _vm.isNotDirty,
                                },
                                on: { click: _vm.saveProfile },
                              },
                              [_vm._v(_vm._s(_vm.$t("saveLabel")))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ],
              1
            ),
          ]),
          _c(
            "v-tab-item",
            [
              _c("v-form", { ref: "securityForm" }, [
                _c(
                  "div",
                  { staticClass: "d-flex justify-center" },
                  [
                    _c(
                      "v-card",
                      {
                        attrs: {
                          flat: "",
                          width: "100%",
                          "max-width": "800px",
                        },
                      },
                      [
                        _c(
                          "perfect-scrollbar",
                          { style: { "max-height": _vm.scrollHeight } },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "px-0 py-0" },
                              [
                                _c(
                                  "v-container",
                                  { staticClass: "px-10" },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                outlined: "",
                                                "hide-details": "auto",
                                                label:
                                                  _vm.$t(
                                                    "currentPasswordLabel"
                                                  ) + "*",
                                                "aria-required": "true",
                                                "error-messages":
                                                  _vm.passwordErrors,
                                                type: "password",
                                              },
                                              model: {
                                                value: _vm.userPassword,
                                                callback: function ($$v) {
                                                  _vm.userPassword = $$v
                                                },
                                                expression: "userPassword",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                outlined: "",
                                                "hide-details": "auto",
                                                label:
                                                  _vm.$t("newPasswordLabel") +
                                                  "*",
                                                "aria-required": "true",
                                                "error-messages":
                                                  _vm.newPasswordErrors,
                                                type: "password",
                                              },
                                              model: {
                                                value: _vm.newPassword,
                                                callback: function ($$v) {
                                                  _vm.newPassword = $$v
                                                },
                                                expression: "newPassword",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                outlined: "",
                                                "hide-details": "auto",
                                                label:
                                                  _vm.$t(
                                                    "confirmPasswordLabel"
                                                  ) + "*",
                                                "aria-required": "true",
                                                type: "password",
                                              },
                                              model: {
                                                value: _vm.confirmPassword,
                                                callback: function ($$v) {
                                                  _vm.confirmPassword = $$v
                                                },
                                                expression: "confirmPassword",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "justify-end px-10 pt-3" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  width: "175px",
                                  color: "error",
                                  disabled: _vm.disableSaveSecurity,
                                },
                                on: { click: _vm.saveSecurity },
                              },
                              [_vm._v(_vm._s(_vm.$t("saveLabel")))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm.userMode === "T"
            ? _c("v-tab-item", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-center" },
                  [
                    _c(
                      "v-card",
                      {
                        attrs: {
                          flat: "",
                          width: "100%",
                          "max-width": "800px",
                        },
                      },
                      [
                        _c(
                          "v-card-actions",
                          { staticClass: "justify-center" },
                          [
                            _c(
                              "div",
                              { staticClass: "text-center" },
                              [
                                !_vm.hasToken
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "login-with-google-btn",
                                        attrs: { type: "button" },
                                        on: { click: _vm.connectGoogle },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("signInWithGoogleLabel")
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.hasToken
                                  ? _c("div", [
                                      _vm._v(_vm._s(_vm.profile.email)),
                                    ])
                                  : _vm._e(),
                                _vm.hasToken
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          width: "100px",
                                          color: "primary",
                                        },
                                        on: { click: _vm.disconnectGoogle },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("disconnectLabel"))
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "perfect-scrollbar",
                          {
                            style: {
                              "max-height": _vm.$vuetify.breakpoint.mdAndUp
                                ? "calc(100vh - 230px)"
                                : "calc(100vh - 280px)",
                            },
                          },
                          [
                            _vm.hasToken
                              ? _c(
                                  "v-card-text",
                                  { staticClass: "px-0 py-0" },
                                  [
                                    _c(
                                      "v-container",
                                      { staticClass: "px-10" },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "v-card",
                                                  {
                                                    staticClass: "mt-2",
                                                    attrs: {
                                                      outlined: "",
                                                      width: "100%",
                                                      "max-width": "800px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-card-text",
                                                      {
                                                        staticStyle: {
                                                          padding: "0px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "title-on-border",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "calendarsLabel"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-list",
                                                      [
                                                        _vm._l(
                                                          _vm.calendarItems,
                                                          function (item, i) {
                                                            return [
                                                              true
                                                                ? _c(
                                                                    "v-list-item",
                                                                    {
                                                                      key: item.email,
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-inline-block color-bar",
                                                                          style:
                                                                            {
                                                                              backgroundColor:
                                                                                item.color,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              id:
                                                                                "googleCalendar" +
                                                                                i,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "d-inline-block",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.display
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-list-item-action",
                                                                        [
                                                                          _c(
                                                                            "v-switch",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "blue darken-1",
                                                                                  inset:
                                                                                    "",
                                                                                  "input-value":
                                                                                    item.selected,
                                                                                  "aria-labelledby":
                                                                                    "googleCalendar" +
                                                                                    i,
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.onCheckedChanged(
                                                                                      item
                                                                                    )
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              i <
                                                              _vm.calendarItems
                                                                .length -
                                                                1
                                                                ? _c(
                                                                    "v-divider",
                                                                    {
                                                                      key: i,
                                                                      attrs: {
                                                                        "aria-hidden":
                                                                          "true",
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "v-card",
                                                  {
                                                    staticClass: "mt-2",
                                                    attrs: {
                                                      outlined: "",
                                                      width: "100%",
                                                      "max-width": "800px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-card-text",
                                                      {
                                                        staticStyle: {
                                                          padding: "0px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "title-on-border",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "classroomLabel"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-list",
                                                      [
                                                        _vm._l(
                                                          _vm.classItems,
                                                          function (item, i) {
                                                            return [
                                                              true
                                                                ? _c(
                                                                    "v-list-item",
                                                                    {
                                                                      key: item.email,
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-inline-block color-bar",
                                                                          style:
                                                                            {
                                                                              backgroundColor:
                                                                                item.color,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              id:
                                                                                "googleClass" +
                                                                                i,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "d-inline-block",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.name
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-list-item-action",
                                                                        [
                                                                          _c(
                                                                            "v-switch",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "blue darken-1",
                                                                                  inset:
                                                                                    "",
                                                                                  "input-value":
                                                                                    item.selected,
                                                                                  "aria-labelledby":
                                                                                    "googleClass" +
                                                                                    i,
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.onClassroomCheckedChanged(
                                                                                      item
                                                                                    )
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              i <
                                                              _vm.classItems
                                                                .length -
                                                                1
                                                                ? _c(
                                                                    "v-divider",
                                                                    {
                                                                      key: i,
                                                                      attrs: {
                                                                        "aria-hidden":
                                                                          "true",
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.hasToken
                          ? _c(
                              "v-card-actions",
                              { staticClass: "justify-end px-10 mt-3" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      width: "175px",
                                      color: "error",
                                      disabled: _vm.isNotDirty,
                                    },
                                    on: { click: _vm.saveGoogle },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("saveLabel")))]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("v-tab-item", [
            _c(
              "div",
              { staticClass: "d-flex justify-center" },
              [
                _c(
                  "v-card",
                  { attrs: { flat: "", width: "100%", "max-width": "800px" } },
                  [
                    _c(
                      "perfect-scrollbar",
                      {
                        style: {
                          "max-height": _vm.$vuetify.breakpoint.mdAndUp
                            ? "calc(100vh - 100px)"
                            : "calc(100vh - 150px)",
                        },
                      },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "px-0 py-0" },
                          [
                            _c(
                              "v-container",
                              { staticClass: "px-10" },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          sm: "8",
                                          "offset-sm": "2",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "d-table" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "d-table-row",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-table-cell text-right pr-5",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "memberSinceLabel"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-table-cell text-right font-weight-bold",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.registeredDate
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "d-table-row",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-table-cell pr-5",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "subscriptionExpiresLabel"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-table-cell font-weight-bold",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.paidThroughDate
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm.canUpdateSubscription
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-center mt-3 ml-3",
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "mb-1",
                                                    attrs: { color: "primary" },
                                                    on: { click: _vm.renew },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "renewSubscriptionLabel"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                ["M", "A"].includes(
                                                  _vm.userMode
                                                )
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "mb-1 ml-2",
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.addAccounts,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "addAccountsLabel"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass: "text-center mt-4",
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "notDistrictAdminBillingLabel"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm.paymentItems && _vm.paymentItems.length > 0
                                  ? _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass: "mt-2",
                                                attrs: {
                                                  outlined: "",
                                                  width: "100%",
                                                  "max-width": "800px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-card-text",
                                                  {
                                                    staticStyle: {
                                                      padding: "0px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "title-on-border",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "paymentsLabel"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "perfect-scrollbar",
                                                      {
                                                        staticStyle: {
                                                          "max-height":
                                                            "calc(100vh - 370px)",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-list",
                                                          [
                                                            _vm._l(
                                                              _vm.paymentItems,
                                                              function (
                                                                item,
                                                                i
                                                              ) {
                                                                return [
                                                                  true
                                                                    ? _c(
                                                                        "v-list-item",
                                                                        {
                                                                          key: item.paymentId,
                                                                          attrs:
                                                                            {
                                                                              "two-line":
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-list-item-content",
                                                                            [
                                                                              _c(
                                                                                "v-list-item-title",
                                                                                {
                                                                                  staticClass:
                                                                                    "font-weight-bold",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item.label
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm.userMode ===
                                                                              "T"
                                                                                ? [
                                                                                    _c(
                                                                                      "v-list-item-subtitle",
                                                                                      {
                                                                                        staticClass:
                                                                                          "green--text text-lighten-1",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "datePaidLabel",
                                                                                              {
                                                                                                date: item.paymentDisplayDate,
                                                                                              }
                                                                                            )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-list-item-subtitle",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "yearSubscriptionLabel",
                                                                                              {
                                                                                                years:
                                                                                                  item.numYears,
                                                                                              }
                                                                                            )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                : [
                                                                                    _c(
                                                                                      "v-list-item-subtitle",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "numAccountsLabel",
                                                                                              {
                                                                                                accounts:
                                                                                                  item.numTeachers,
                                                                                              }
                                                                                            )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    !item.hasPaymentDate
                                                                                      ? _c(
                                                                                          "v-list-item-subtitle",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "dateSentLabel",
                                                                                                  {
                                                                                                    date: item.sentDisplayDate,
                                                                                                  }
                                                                                                )
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    item.hasPaymentDate
                                                                                      ? _c(
                                                                                          "v-list-item-subtitle",
                                                                                          {
                                                                                            staticClass:
                                                                                              "green--text text-lighten-1",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "datePaidLabel",
                                                                                                  {
                                                                                                    date: item.paymentDisplayDate,
                                                                                                  }
                                                                                                )
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ],
                                                                            ],
                                                                            2
                                                                          ),
                                                                          _c(
                                                                            "v-list-item-action",
                                                                            [
                                                                              _c(
                                                                                "v-list-item-action-text",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      "font-size":
                                                                                        "16px",
                                                                                    },
                                                                                  style:
                                                                                    item.paymentAmountStyle,
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      `US$ ${item.paymentDisplayAmount}`
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "pr-2 text-center justify-center",
                                                                                },
                                                                                [
                                                                                  _vm.userMode !==
                                                                                    "T" &&
                                                                                  !item.hasPaymentDate
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-inline-block",
                                                                                          attrs:
                                                                                            {
                                                                                              color:
                                                                                                "error",
                                                                                              text: "",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.$refs.payInvoice.pay(
                                                                                                  item
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " Pay Now "
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-inline-block",
                                                                                      attrs:
                                                                                        {
                                                                                          small:
                                                                                            "",
                                                                                          text: "",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "printInvoiceLabel"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.printInvoice(
                                                                                              item.paymentId
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              "font-size":
                                                                                                "20px",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "fal fa-arrow-up-from-square"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  i <
                                                                  _vm
                                                                    .paymentItems
                                                                    .length -
                                                                    1
                                                                    ? _c(
                                                                        "v-divider",
                                                                        {
                                                                          key: i,
                                                                          attrs:
                                                                            {
                                                                              "aria-hidden":
                                                                                "true",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("v-tab-item", [
            _c(
              "div",
              { staticClass: "d-flex justify-center" },
              [
                _c(
                  "v-card",
                  { attrs: { flat: "", width: "100%", "max-width": "800px" } },
                  [
                    _c(
                      "perfect-scrollbar",
                      { style: { "max-height": _vm.scrollHeight } },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "px-0 py-0" },
                          [
                            _c(
                              "v-container",
                              { staticClass: "px-10" },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass: "mt-2",
                                            attrs: {
                                              outlined: "",
                                              width: "100%",
                                              "max-width": "800px",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              { staticClass: "pa-0" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "title-on-border",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("emailLabel")
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-list",
                                                  [
                                                    _c(
                                                      "v-list-item",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-inline-block",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "allNotificationsLabel"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-list-item-action",
                                                          [
                                                            _c("v-switch", {
                                                              attrs: {
                                                                color:
                                                                  "blue darken-1",
                                                                inset: "",
                                                                "input-value":
                                                                  _vm.allEmailNotifications,
                                                                "aria-label":
                                                                  _vm.$t(
                                                                    "allEmailNotificationsLabel"
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.onEmailNotificationsChanged,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c("v-divider", {
                                                      attrs: {
                                                        "aria-hidden": "true",
                                                      },
                                                    }),
                                                    _c(
                                                      "v-list-item",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "sendEmailLabel"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-list-item-action",
                                                          [
                                                            _c("v-switch", {
                                                              attrs: {
                                                                color:
                                                                  "blue darken-1",
                                                                inset: "",
                                                                "aria-label":
                                                                  _vm.$t(
                                                                    "sendEmailLabel"
                                                                  ),
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.sendEmail,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.sendEmail =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "sendEmail",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c("v-divider", {
                                                      attrs: {
                                                        "aria-hidden": "true",
                                                      },
                                                    }),
                                                    _c(
                                                      "v-list-item",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "sendEmailLabel2"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-list-item-action",
                                                          [
                                                            _c("v-switch", {
                                                              attrs: {
                                                                color:
                                                                  "blue darken-1",
                                                                inset: "",
                                                                "aria-label":
                                                                  _vm.$t(
                                                                    "sendEmailLabel2"
                                                                  ),
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.sendEmail2,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.sendEmail2 =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "sendEmail2",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    !_vm.$currentUser.isManager
                                                      ? [
                                                          _c("v-divider", {
                                                            attrs: {
                                                              "aria-hidden":
                                                                "true",
                                                            },
                                                          }),
                                                          _c(
                                                            "v-list-item",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "sendEmailLabel3"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-list-item-action",
                                                                [
                                                                  _c(
                                                                    "v-switch",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "blue darken-1",
                                                                        inset:
                                                                          "",
                                                                        "aria-label":
                                                                          _vm.$t(
                                                                            "sendEmailLabel3"
                                                                          ),
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.sendEmail3,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.sendEmail3 =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "sendEmail3",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c("v-divider", {
                                                            attrs: {
                                                              "aria-hidden":
                                                                "true",
                                                            },
                                                          }),
                                                          _c(
                                                            "v-list-item",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "sendEmailLabel4"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-list-item-action",
                                                                [
                                                                  _c(
                                                                    "v-switch",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "blue darken-1",
                                                                        inset:
                                                                          "",
                                                                        "aria-label":
                                                                          _vm.$t(
                                                                            "sendEmailLabel4"
                                                                          ),
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.sendEmail4,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.sendEmail4 =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "sendEmail4",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c("v-divider", {
                                                            attrs: {
                                                              "aria-hidden":
                                                                "true",
                                                            },
                                                          }),
                                                          _c(
                                                            "v-list-item",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "sendEmailLabel7"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-list-item-action",
                                                                [
                                                                  _c(
                                                                    "v-switch",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "blue darken-1",
                                                                        inset:
                                                                          "",
                                                                        "aria-label":
                                                                          _vm.$t(
                                                                            "sendEmailLabel7"
                                                                          ),
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.sendEmail7,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.sendEmail7 =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "sendEmail7",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                    _c("v-divider", {
                                                      attrs: {
                                                        "aria-hidden": "true",
                                                      },
                                                    }),
                                                    _c(
                                                      "v-list-item",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "sendEmailLabel5"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-list-item-action",
                                                          [
                                                            _c("v-switch", {
                                                              attrs: {
                                                                color:
                                                                  "blue darken-1",
                                                                inset: "",
                                                                "aria-label":
                                                                  _vm.$t(
                                                                    "sendEmailLabel5"
                                                                  ),
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.sendEmail5,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.sendEmail5 =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "sendEmail5",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c("v-divider", {
                                                      attrs: {
                                                        "aria-hidden": "true",
                                                      },
                                                    }),
                                                    _c(
                                                      "v-list-item",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "sendEmailLabel6"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-list-item-action",
                                                          [
                                                            _c("v-switch", {
                                                              attrs: {
                                                                color:
                                                                  "blue darken-1",
                                                                inset: "",
                                                                "aria-label":
                                                                  _vm.$t(
                                                                    "sendEmailLabel6"
                                                                  ),
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.sendEmail6,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.sendEmail6 =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "sendEmail6",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm.$currentUser
                                                      .isTeacher ||
                                                    _vm.$currentUser.isDualUser
                                                      ? _c("v-divider", {
                                                          attrs: {
                                                            "aria-hidden":
                                                              "true",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    _vm.$currentUser
                                                      .isTeacher ||
                                                    _vm.$currentUser.isDualUser
                                                      ? _c(
                                                          "v-list-item",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "sendEmailLabel8"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-item-action",
                                                              [
                                                                _c("v-switch", {
                                                                  attrs: {
                                                                    color:
                                                                      "blue darken-1",
                                                                    inset: "",
                                                                    "aria-label":
                                                                      _vm.$t(
                                                                        "sendEmailLabel8"
                                                                      ),
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.sendEmail8,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.sendEmail8 =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "sendEmail8",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass: "mt-2",
                                            attrs: {
                                              outlined: "",
                                              width: "100%",
                                              "max-width": "800px",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              {
                                                staticStyle: { padding: "0px" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "title-on-border",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("bannerLabel")
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-list",
                                                  [
                                                    _c(
                                                      "v-list-item",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-inline-block",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "allNotificationsLabel"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-list-item-action",
                                                          [
                                                            _c("v-switch", {
                                                              attrs: {
                                                                color:
                                                                  "blue darken-1",
                                                                inset: "",
                                                                "aria-label":
                                                                  _vm.$t(
                                                                    "allBannerNotificationsLabel"
                                                                  ),
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.showBanner,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.showBanner =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "showBanner",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c("v-divider", {
                                                      attrs: {
                                                        "aria-hidden": "true",
                                                      },
                                                    }),
                                                    _c(
                                                      "v-list-item",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "newBannerMessageLabel"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-list-item-action",
                                                          [
                                                            _c("v-switch", {
                                                              attrs: {
                                                                color:
                                                                  "blue darken-1",
                                                                inset: "",
                                                                "aria-label":
                                                                  _vm.$t(
                                                                    "newBannerMessageLabel"
                                                                  ),
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.showBanner,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.showBanner =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "showBanner",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                !_vm.$currentUser.isManager
                                  ? _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass: "mt-2",
                                                attrs: {
                                                  outlined: "",
                                                  width: "100%",
                                                  "max-width": "800px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-card-text",
                                                  {
                                                    staticStyle: {
                                                      padding: "0px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "title-on-border",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "eventAlertsLabel"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-list",
                                                      [
                                                        _c(
                                                          "v-list-item",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "allTypesLabel"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-item-action",
                                                              [
                                                                _c("v-switch", {
                                                                  attrs: {
                                                                    color:
                                                                      "blue darken-1",
                                                                    inset: "",
                                                                    "input-value":
                                                                      _vm.allEventNotifications,
                                                                    "aria-label":
                                                                      _vm.$t(
                                                                        "allTypesLabel"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.onEventNotificationsChanged,
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c("v-divider", {
                                                          attrs: {
                                                            "aria-hidden":
                                                              "true",
                                                          },
                                                        }),
                                                        _c(
                                                          "v-list-item",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "allowPushLabel"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-item-action",
                                                              [
                                                                _c("v-switch", {
                                                                  attrs: {
                                                                    color:
                                                                      "blue darken-1",
                                                                    inset: "",
                                                                    "aria-label":
                                                                      _vm.$t(
                                                                        "allowPushLabel"
                                                                      ),
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.allowPush,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.allowPush =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "allowPush",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c("v-divider", {
                                                          attrs: {
                                                            "aria-hidden":
                                                              "true",
                                                          },
                                                        }),
                                                        _c(
                                                          "v-list-item",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "emailLabel"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-item-action",
                                                              [
                                                                _c("v-switch", {
                                                                  attrs: {
                                                                    color:
                                                                      "blue darken-1",
                                                                    inset: "",
                                                                    "aria-label":
                                                                      _vm.$t(
                                                                        "emailLabel"
                                                                      ),
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.eventEmail,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.eventEmail =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "eventEmail",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "justify-end px-10 mt-3" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              width: "175px",
                              color: "error",
                              disabled: _vm.isNotDirty,
                            },
                            on: { click: _vm.saveSettings },
                          },
                          [_vm._v(_vm._s(_vm.$t("saveLabel")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("v-tab-item", [
            _c(
              "div",
              { staticClass: "d-flex justify-center" },
              [
                _c(
                  "v-card",
                  { attrs: { flat: "", width: "100%", "max-width": "800px" } },
                  [
                    _c(
                      "perfect-scrollbar",
                      { style: { "max-height": _vm.scrollHeight } },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "px-0 py-0" },
                          [
                            _c(
                              "v-container",
                              { staticClass: "px-10" },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pb-0",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            outlined: "",
                                            label: _vm.$t(
                                              "sessionExpirationLabel"
                                            ),
                                            "hide-details": "auto",
                                            items: _vm.sessionItems,
                                          },
                                          model: {
                                            value: _vm.sessionTimeout,
                                            callback: function ($$v) {
                                              _vm.sessionTimeout = $$v
                                            },
                                            expression: "sessionTimeout",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pt-0",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  text: "",
                                                  color: "error",
                                                },
                                                on: {
                                                  click:
                                                    _vm.signOutAllOtherDevices,
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("fal fa-sign-out-alt"),
                                                ]),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-inline-block pl-1",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "signoutOfAllOtherDevices"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  text: "",
                                                  color: "error",
                                                },
                                                on: {
                                                  click: _vm.clearSessionCache,
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("fal fa-cloud-slash"),
                                                ]),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-inline-block pl-1",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Clear Session Cache"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            (_vm.allowPush || _vm.eventEmail) && _vm.tab == 5
                              ? _c("notification-devices")
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "justify-end px-10 mt-3" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              width: "175px",
                              color: "error",
                              disabled: _vm.isNotDirty,
                            },
                            on: { click: _vm.saveSettings },
                          },
                          [_vm._v(_vm._s(_vm.$t("saveLabel")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("pb-confirm", { ref: "confirm" }),
      _c("subscription-renewal", { ref: "subscriptionRenewal" }),
      ["M", "A"].includes(_vm.userMode)
        ? _c("add-school-accounts", { ref: "addSchoolAccounts" })
        : _vm._e(),
      ["M", "A"].includes(_vm.userMode)
        ? _c("school-subscription-renewal", {
            ref: "schoolSubscriptionRenewal",
          })
        : _vm._e(),
      _c("pay-invoice", { ref: "payInvoice" }),
      _c(
        "pb-base-modal",
        {
          attrs: { maxWidth: 600, expandable: false, withActions: false },
          on: { apply: _vm.applyPhoto },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_vm._v(_vm._s(_vm.$t("editPhotoLabel")))]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.isChoosingPhoto,
            callback: function ($$v) {
              _vm.isChoosingPhoto = $$v
            },
            expression: "isChoosingPhoto",
          },
        },
        [
          _c(
            "v-container",
            { staticClass: "pb-7" },
            [
              _c(
                "v-row",
                { staticClass: "d-flex flex-nowrap" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-center",
                      attrs: { cols: "4" },
                    },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            outlined: "",
                            width: "160px",
                            height: "218px",
                          },
                        },
                        [
                          _c("v-card-text", [
                            _c("span", { staticClass: "title-on-border" }, [
                              _vm._v(_vm._s(_vm.$t("photoLabel"))),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "text-center",
                              staticStyle: { height: "140px" },
                            },
                            [
                              _vm.hasLocalPhotoUrl
                                ? _c("img", {
                                    staticStyle: {
                                      width: "135px",
                                      height: "135px",
                                      "object-fit": "cover",
                                    },
                                    attrs: {
                                      src: _vm.localPhotoUrl,
                                      role: "presentation",
                                    },
                                  })
                                : _c(
                                    "v-icon",
                                    { attrs: { size: "120px", color: "grey" } },
                                    [_vm._v("fas fa-user")]
                                  ),
                              _c("input", {
                                ref: "fileInput",
                                staticClass: "d-none",
                                attrs: {
                                  type: "file",
                                  accept:
                                    ".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*",
                                },
                                on: { change: _vm.onFileChanged },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    small: "",
                                    text: "",
                                    loading: _vm.selectingFile,
                                  },
                                  on: { click: _vm.selectImage },
                                },
                                [_vm._v(_vm._s(_vm.$t("uploadLabel")))]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "error",
                                    small: "",
                                    text: "",
                                  },
                                  on: { click: _vm.removePhoto },
                                },
                                [_vm._v(_vm._s(_vm.$t("removeLabel")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "8" } }, [
                    _c("div", { staticClass: "mb-4" }, [
                      _vm._v(_vm._s(_vm.$t("photoGalleryLabel"))),
                    ]),
                    _c(
                      "div",
                      { staticStyle: { overflow: "none", maxHeight: "200px" } },
                      _vm._l(2, function (indexI) {
                        return _c(
                          "v-row",
                          {
                            key: indexI,
                            staticClass: "d-flex flex-nowrap mb-2",
                            class: { "mt-0": indexI === 1 },
                          },
                          _vm._l(5, function (indexJ) {
                            return _c(
                              "div",
                              {
                                key: indexJ,
                                attrs: {
                                  tabindex: "0",
                                  role: "button",
                                  "aria-label": _vm.$t("defaultPhotoLabel", {
                                    num: (indexI - 1) * 5 + indexJ + 1,
                                  }),
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.selectDefault((indexI - 1) * 5 + indexJ)
                                  },
                                  click: function ($event) {
                                    _vm.selectDefault((indexI - 1) * 5 + indexJ)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "px-2",
                                  staticStyle: {
                                    width: "75px",
                                    height: "75px",
                                    cursor: "pointer",
                                  },
                                  attrs: {
                                    src: _vm.defaultPhotoUrls(
                                      (indexI - 1) * 5 + indexJ
                                    ),
                                    role: "presentation",
                                  },
                                }),
                              ]
                            )
                          }),
                          0
                        )
                      }),
                      1
                    ),
                    _c("div"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }