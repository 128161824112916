




























































import DateTimeUtils from '@/utils/date-time-utils';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ld from 'lodash';
import Confirm from '../core/Confirm.vue';
import { timeZoneLocations } from '@/constants/time-zone-location';

const firebase = namespace('firebase');
const settings = namespace('settings');

@Component
export default class NotificationDevices extends Vue {
    @firebase.Action
    getAllDevices!: () => Promise<any>;

    @firebase.Action
    signOut!: (token?: string) => Promise<any>;

    @firebase.Action
    signOutAll!: (token?: string) => Promise<any>;

    @firebase.Action
    registerToken!: (params?: any) => Promise<any>;

    @firebase.Action
    updateEmail!: (params?: any) => Promise<any>;

    @settings.Getter
    getEmailAddress!: any;

    @settings.Getter('getUserInfo')
    userInfo!: any;

    $refs!: {
      confirm: Confirm
    }

    get timeZones() {
      return Object.keys(timeZoneLocations);
    }

    get pushDevicesEnabled() {
      return this.userInfo.otherSettings.allowPush;
    }

    get eventEmailEnabled() {
      return this.userInfo.otherSettings.eventEmail;
    }

    notificationDevicesPush: Array<any> = [];
    notificationDevicesEmail: Array<any> = [];
    currentDate = DateTimeUtils.currentDateAddDays(0);

    headersPush = [
      { text: this.$t('nameLabel'), value: 'deviceName', width: 250 },
      { text: this.$t('lastActiveLabel'), value: 'lastUpdate', width: 150 },
      { text: this.$t('removeAllLabel'), value: 'action', width: 150, sortable: false }
    ];

    headersEmail = [
      { text: this.$t('nameLabel'), value: 'deviceName', width: 150 },
      { text: this.$t('timeZoneLabel'), value: 'action', width: 150, sortable: false }
    ];

    get formatToDisplay() {
      return (d: string) => {
        if (d === this.currentDate) {
          return this.$t('todayLabel');
        } else {
          return DateTimeUtils.formatToDisplay(d);
        }
      }
    }

    loadFirebaseDevices() {
      this.getAllDevices().then((d: any) => {
        this.notificationDevicesPush = d.data.devices.filter((d: any) => d.deviceId !== 'email').map((d: any) => {
          d.hover = false;
          d.edit = false;
          return d;
        });
        this.notificationDevicesEmail = d.data.devices.filter((d: any) => d.deviceId === 'email');
      })
    }

    created() {
      this.loadFirebaseDevices();
    }

    // email
    showEmailInfo() {
      this.$refs.confirm.alert({
        title: this.$t('emailNotificationsLabel'),
        text: this.$t('emailNotifInfo').toString(),
        option1ButtonAlternativeText: this.$t('okLabel')
      })
    }

    updateTimeZone() {
      this.updateEmail({ timeZone: this.notificationDevicesEmail[0].timeZone }).then(() => {
        this.loadFirebaseDevices();
      })
    }

    // push devices
    removeAllDevices() {
      this.$refs.confirm.confirm({
        title: this.$t('NotificationDevicesLabel'),
        text: this.$t('removeDevicesConfirm'),
        option1ButtonAlternativeText: this.$t('continueLabel')
      }).then(async result => {
        if (result === 1) {
          this.signOutAll().then(() => {
            this.loadFirebaseDevices();
          });
        }
      })
    }

    removeDevice(device: any) {
      this.$refs.confirm.confirm({
        title: this.$t('NotificationDevicesLabel'),
        text: this.$t('removeDeviceConfirm'),
        option1ButtonAlternativeText: this.$t('continueLabel')
      }).then(async result => {
        if (result === 1) {
          this.signOut(device.deviceId).then(() => {
            this.loadFirebaseDevices();
          });
        }
      })
    }

    renameDevice(device: any) {
      this.registerToken({ token: device.deviceId, name: device.deviceName, rename: true }).then(() => {
        this.loadFirebaseDevices();
      })
    }

    mouseOverItem(item: any) {
      const devices = ld.cloneDeep(this.notificationDevicesPush);
      devices.forEach((e: any) => { e.hover = false; });
      const event = devices.find((e: any) => e.deviceId === item.deviceId);
      event.hover = true;
      this.notificationDevicesPush = devices;
    }

    mouseLeaveItem() {
      const devices = ld.cloneDeep(this.notificationDevicesPush);
      devices.forEach((e: any) => { e.hover = false; });
      this.notificationDevicesPush = devices;
    }
}

