
import { Deferred } from '@/common/deferred';
import { emailFormat, E_NOTIFICATIONS, E_NOTIFICATIONS_ADMIN, EVENT_NOTIFICATIONS, sessionItems } from '@/constants';
import { FormError } from '@/errors';
import AddSchoolAccounts from '@/components/payments/AddSchoolAccounts.vue';
import SchoolSubscriptionRenewal from '@/components/payments/SchoolSubscriptionRenewal.vue';
import SubscriptionRenewal from '@/components/payments/SubscriptionRenewal.vue';
import NotificationDevices from '@/components/notifications/NotificationDevices.vue';
import FileServices from '@/services/file-services';
import GoogleAppServices from '@/services/google-app-services';
import googleServices from '@/services/google-services';
import UserServices from '@/services/user-services';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import ld from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Framework } from 'vuetify';
import Vuetify from 'vuetify/lib';
import { namespace } from 'vuex-class';
import Confirm from '@/components/core/Confirm.vue';
import PayInvoice from '@/components/payments/PayInvoice.vue';

const settings = namespace('settings');
const google = namespace('google');
const prints = namespace('prints');
const plans = namespace('plans');
const firebase = namespace('firebase');
const index = namespace('index');

@Component({
  components: {
    SubscriptionRenewal,
    SchoolSubscriptionRenewal,
    AddSchoolAccounts,
    NotificationDevices,
    PayInvoice
  }
})
export default class Account extends Vue {
    changeEmail = false;
    changePassword = false;
    confirmPassword = '';
    showGoogleConnect = true;
    showNotifications = true;
    showPayments = true;
    classItems: Array<any> = [];
    tab = 0;
    selectedFile: any = null;
    selectingFile = false;
    passwordErrors: Array<any> = [];
    newPasswordErrors: Array<any> = [];
    emailErrors: Array<any> = [];
    isChoosingPhoto = false;
    localPhotoUrl = '';

    $refs!: {
      fileInput: HTMLInputElement,
      profileForm: Vue & { validate: () => boolean, resetValidation: () => void }
      securityForm: Vue & { validate: () => boolean, resetValidation: () => void }
      confirm: Confirm,
      payInvoice: PayInvoice,
      subscriptionRenewal: SubscriptionRenewal,
      schoolSubscriptionRenewal: SchoolSubscriptionRenewal,
      addSchoolAccounts: AddSchoolAccounts
    }

    @settings.Getter('getUserInfo')
    userInfo!: any;

    @settings.Getter('getUserMode')
    userMode!: string;

    @settings.Getter('getShowSnackbarNotifications')
    showSnackbarNotifications!: boolean;

    @settings.Getter
    getPushNotificationsEnabled!: string;

    @settings.Getter
    getSchoolSettings!: any;

    @google.State
    googleClasses!: Array<any>;

    @google.State
    calendars!: Array<any>;

    @google.State
    profile!: any;

    @index.Getter
    hasBanner!: boolean;

    @settings.Action('save')
    saveAccount!: (params?: any) => Promise<any>;

    @settings.Action
    reloadSettings!: (params?: any) => Promise<any>;

    @google.Action
    loadCalendars!: (params?: any) => Promise<any>;

    @google.Action
    loadGoogleClasses!: (params?: any) => Promise<any>;

    @settings.Mutation
    setUpdatedSettings!: (params?: any) => void;

    @google.Mutation
    clear!: (params?: any) => void;

    @settings.Mutation
    clearAccessToken!: (params?: any) => void;

    @settings.Mutation
    setAccessToken!: (params?: any) => void;

    @settings.Action
    pay!: (params?: any) => Promise<any>;

    @settings.Action
    loadAdminPayments!: (params?: any) => Promise<any>;

    @prints.Action
    print!: (params?: any) => Promise<any>;

    @plans.Mutation
    resetPlans!: () => void;

    @firebase.Action
    signOutAllOther!: (params?: any) => Promise<any>;

    $vuetify!: Vuetify & Framework

    get hasLocalPhotoUrl() {
      return CommonUtils.hasText(this.localPhotoUrl);
    }

    get sessionItems() {
      return sessionItems;
    }

    get emailAddressRule() {
      return [
        (v: string) => !!v || this.$t('requiredMsg2'),
        (v: string) => emailFormat.test(v) || this.$t('emailMustBeValidMsg')
      ]
    }

    get EMAIL_NOTIFICATIONS() {
      if (this.$currentUser.isAdmin && !this.$currentUser.isDualUser) {
        return E_NOTIFICATIONS_ADMIN;
      } else {
        return E_NOTIFICATIONS;
      }
    }

    get allEmailNotifications(): boolean {
      const that: any = this;
      for (const i in this.EMAIL_NOTIFICATIONS) {
        if (!that[this.EMAIL_NOTIFICATIONS[i]]) {
          return false;
        }
      }
      return true;
    }

    get allEventNotifications(): boolean {
      const that: any = this;
      for (const i in EVENT_NOTIFICATIONS) {
        if (!that[EVENT_NOTIFICATIONS[i]]) {
          return false;
        }
      }
      return true;
    }

    get isNotDirty() {
      return !(this.updatedSettings && Object.keys(this.updatedSettings).length > 0);
    }

    get disableSaveSecurity() {
      return (CommonUtils.hasNoText(this.userPassword) || CommonUtils.hasNoText(this.newPassword) || CommonUtils.hasNoText(this.confirmPassword))
    }

    get calendarItems() {
      const items = [];
      let count = 0;
      ld.cloneDeep(this.calendars).forEach(c => {
        if (this.googleEmail.includes(c.email)) {
          c.selected = true;
          count++;
        } else {
          c.selected = false;
        }
        items.push(c);
      });
      items.unshift({
        color: this.$vuetify.theme.currentTheme.primary,
        display: 'All Calendars',
        email: 'allcalendars@planbook.com',
        primary: 'N',
        selected: count > 0 && count === this.calendars.length
      });
      return items;
    }

    get registeredDate(): string {
      return DateTimeUtils.formatToShow(this.userInfo.accountSettings.registeredDate);
    }

    get paidThroughDate(): string {
      return DateTimeUtils.formatToShow(this.userInfo.accountSettings.paidThroughDate);
    }

    get formatToShow() {
      return DateTimeUtils.formatToShow;
    }

    get hasText() {
      return CommonUtils.hasText;
    }

    get hasToken(): boolean {
      return this.userInfo.accountSettings.hasToken === 'Y';
    }

    get payments(): Array<any> {
      return this.userInfo.accountSettings.payments || [];
    }

    get adminPayments(): Array<any> {
      return this.userInfo.accountSettings.adminPayments || [];
    }

    get paymentItems() {
      const payments = ['M', 'A'].includes(this.userMode) ? this.adminPayments : this.payments;
      return payments.map(payment => {
        const p = ld.cloneDeep(payment);
        p.label = this.getPaymentLabel(p);
        p.hasPaymentDate = CommonUtils.hasText(p.paymentDate);
        p.paymentDisplayDate = DateTimeUtils.formatToDisplay(p.paymentDate, true, true);
        p.sentDisplayDate = DateTimeUtils.formatToDisplay(p.sentDate, true, true);
        p.paymentAmount = (+p.paymentAmount || 0).toFixed(2);
        p.paymentDisplayAmount = p.paymentAmount >= 0 ? p.paymentAmount : '(' + -1 * p.paymentAmount + ')';
        p.paymentAmountStyle = +p.paymentAmount < 0 ? { color: 'red' } : {};
        return p;
      })
    }

    get hasPaymentItems() {
      return CommonUtils.isNotEmpty(this.paymentItems);
    }

    get scrollHeight() {
      if (this.$vuetify.breakpoint.mdAndUp) return this.hasBanner ? 'calc(100vh - 230px)' : 'calc(100vh - 150px)';
      return this.hasBanner ? 'calc(100vh - 400px)' : 'calc(100vh - 300px)';
    }

    get updatedSettings(): any {
      return this.$store.state.settings.updatedSettings;
    }

    set updatedSettings(value: any) {
      this.setUpdatedSettings(value);
    }

    get googleEmail(): Array<string> {
      return this.getValue('googleEmail', this.userInfo.accountSettings.enableCalendars || []);
    }

    set googleEmail(value: Array<string>) {
      this.setValue('googleEmail', value);
    }

    get oldEmailAddress() {
      return this.userInfo.accountSettings.emailAddress;
    }

    get emailAddress(): string {
      return this.getValue('emailAddress', this.userInfo.accountSettings.emailAddress || '');
    }

    set emailAddress(value: string) {
      this.setValue('emailAddress', value);
    }

    get userPassword(): string {
      return this.getValue('userPassword', '');
    }

    set userPassword(value: string) {
      this.setValue('userPassword', value);
    }

    get newPassword(): string {
      return this.getValue('newPassword', '');
    }

    set newPassword(value: string) {
      this.setValue('newPassword', value);
    }

    get firstName(): string {
      return this.getValue('firstName', this.userInfo.accountSettings.firstName || '');
    }

    set firstName(value: string) {
      this.setValue('firstName', value);
    }

    get lastName(): string {
      return this.getValue('lastName', this.userInfo.accountSettings.lastName || '');
    }

    set lastName(value: string) {
      this.setValue('lastName', value);
    }

    get displayName(): string {
      return this.getValue('displayName', this.userInfo.accountSettings.displayName || '');
    }

    set displayName(value: string) {
      this.setValue('displayName', value);
    }

    get schoolDistrict(): string {
      return this.getValue('schoolDistrict', this.userInfo.schoolSettings.schoolDistrict || '');
    }

    set schoolDistrict(value: string) {
      this.setValue('schoolDistrict', value);
    }

    get districtId(): string {
      return this.getValue('districtId', this.userInfo.schoolSettings.districtId || '') + '';
    }

    get hasPhotoUrl() {
      return CommonUtils.hasText(this.photoUrl);
    }

    get photoUrl() {
      return this.getValue('photoUrl', this.userInfo.accountSettings.photoUrl || '');
    }

    set photoUrl(value: string) {
      this.setValue('photoUrl', value);
    }

    get schoolName(): string {
      return this.getValue('schoolName', this.userInfo.schoolSettings.schoolName || '');
    }

    set schoolName(value: string) {
      this.setValue('schoolName', value);
    }

    get isConnectedToSchool(): boolean {
      return !!this.userInfo.schoolSettings.schoolId;
    }

    get schoolId(): string {
      return this.getValue('schoolId', this.userInfo.schoolSettings.schoolId || '') + '';
    }

    get giftLimit(): number {
      return this.getNumber('giftLimit', +this.userInfo.otherSettings.giftLimit || 0);
    }

    set giftLimit(value: number) {
      this.setValue('giftLimit', value);
    }

    get sessionTimeout(): number {
      return this.getNumber('sessionTimeout', this.userInfo.otherSettings.sessionTimeout || -1);
    }

    set sessionTimeout(value: number) {
      this.setValue('sessionTimeout', value);
    }

    get sendEmail(): boolean {
      return this.getBooleanValue('sendEmail');
    }

    set sendEmail(value: boolean) {
      this.setValue('sendEmail', value);
    }

    get sendEmail2(): boolean {
      return this.getBooleanValue('sendEmail2');
    }

    set sendEmail2(value: boolean) {
      this.setValue('sendEmail2', value);
    }

    get sendEmail3(): boolean {
      return this.getBooleanValue('sendEmail3');
    }

    set sendEmail3(value: boolean) {
      this.setValue('sendEmail3', value);
    }

    get sendEmail4(): boolean {
      return this.getBooleanValue('sendEmail4');
    }

    set sendEmail4(value: boolean) {
      this.setValue('sendEmail4', value);
    }

    get sendEmail5(): boolean {
      return this.getBooleanValue('sendEmail5');
    }

    set sendEmail5(value: boolean) {
      this.setValue('sendEmail5', value);
    }

    get sendEmail6(): boolean {
      return this.getBooleanValue('sendEmail6');
    }

    set sendEmail6(value: boolean) {
      this.setValue('sendEmail6', value);
    }

    get sendEmail7(): boolean {
      return this.getBooleanValue('sendEmail7');
    }

    set sendEmail7(value: boolean) {
      this.setValue('sendEmail7', value);
    }

    get sendEmail8(): boolean {
      return this.getBooleanValue('sendEmail8');
    }

    set sendEmail8(value: boolean) {
      this.setValue('sendEmail8', value);
    }

    get allowPush(): boolean {
      return this.getBooleanValue('allowPush');
    }

    set allowPush(value: boolean) {
      this.setValue('allowPush', value);
    }

    get eventEmail(): boolean {
      return this.getBooleanValue('eventEmail');
    }

    set eventEmail(value: boolean) {
      this.setValue('eventEmail', value);
    }

    get showBanner(): boolean {
      return this.getBooleanValue('showBanner');
    }

    set showBanner(value: boolean) {
      this.setValue('showBanner', value);
    }

    async connectGoogle() {
      const deferred = new Deferred<any>();
      googleServices.connect({
        scope: [
          'https://www.googleapis.com/auth/drive',
          'https://www.googleapis.com/auth/userinfo.email',
          'https://www.googleapis.com/auth/calendar',
          'https://www.googleapis.com/auth/classroom.courses',
          'https://www.googleapis.com/auth/classroom.rosters',
          'https://www.googleapis.com/auth/classroom.coursework.me',
          'https://www.googleapis.com/auth/classroom.coursework.students',
          'https://www.googleapis.com/auth/classroom.announcements',
          'https://www.googleapis.com/auth/classroom.push-notifications',
          'https://www.googleapis.com/auth/classroom.profile.emails'
        ],
        response_type: 'code',
        onAuth: async (authResult: any) => {
          try {
            const resp = await GoogleAppServices.connect(authResult.code);
            this.setAccessToken(resp.headers['gs-token'] || resp.data.gsToken);
            deferred.resolve();
          } catch (e) {
            deferred.reject();
          }
        }
      });
      return deferred.promise.then(() => {
        return this.loadGoogleData();
      });
    }

    async disconnectGoogle() {
      CommonUtils.showLoading();
      return this.$refs.confirm.confirm({
        title: this.$t('disconnectFromGoogleLabel'),
        text: this.$t('disconnectFromGoogleMsg'),
        option1ButtonAlternativeText: this.$t('continueLabel')
      }).then(async (result) => {
        if (result === 1) {
          return GoogleAppServices.disconnect().then(() => {
            this.clear();
            this.clearAccessToken();
            return Promise.resolve();
          });
        } else {
          return Promise.resolve();
        }
      }).finally(() => {
        CommonUtils.hideLoading();
      });
    }

    async signOutAllOtherDevices() {
      CommonUtils.showLoading();
      return this.$refs.confirm.confirm({
        title: this.$t('sessionLabel'),
        text: this.$t('sessionMsg'),
        option1ButtonAlternativeText: this.$t('continueLabel')
      }).then(async (result) => {
        if (result === 1) {
          if (this.getPushNotificationsEnabled) {
            this.signOutAllOther();
          }
          return UserServices.signOutAllOtherDevices();
        } else {
          return Promise.resolve();
        }
      }).finally(() => {
        CommonUtils.hideLoading();
      });
    }

    async clearSessionCache() {
      CommonUtils.showLoading();
      return UserServices.clearSessionCache().finally(() => {
        CommonUtils.hideLoading();
      });
    }

    async uploadSelectedFile() {
      if (this.selectedFile && CommonUtils.hasText(this.photoUrl) && this.photoUrl.startsWith('blob:')) {
        return FileServices.uploadFile(this.selectedFile).then((resp) => {
          this.photoUrl = resp.data.url;
          this.selectedFile = null;
        });
      } else {
        this.selectedFile = null;
        return Promise.resolve();
      }
    }

    async save() {
      return this.saveAccount().then(resp => {
        if (this.showSnackbarNotifications) {
          this.$snotify.success(this.$t('statusMsg23') as string);
        }
        return Promise.resolve(resp);
      });
    }

    async saveProfile() {
      if (this.$refs.profileForm.validate()) {
        CommonUtils.showLoading();
        return this.uploadSelectedFile().then(() => {
          return this.save();
        }).then((resp: any) => {
          if (CommonUtils.isTrue(resp.data.invalidPassword)) {
            this.passwordErrors = [this.$t('schoolYearErrorMsg17')];
            return Promise.reject(new FormError());
          } else if (CommonUtils.isTrue(resp.data.existingEmail)) {
            this.emailErrors = [this.$t('accountErrorMsg8')];
            return Promise.reject(new FormError());
          } else {
            return this.reloadSettings();
          }
        }).finally(CommonUtils.hideLoading);
      }
    }

    async saveSecurity() {
      if (this.$refs.securityForm.validate()) {
        CommonUtils.showLoading();
        return Promise.resolve().then(() => {
          if (CommonUtils.hasNoText(this.userPassword) && (CommonUtils.hasText(this.newPassword) || CommonUtils.hasText(this.confirmPassword))) {
            this.passwordErrors = [this.$t('accountErrorMsg4')];
            return Promise.reject(new FormError());
          } else if (this.newPassword !== this.confirmPassword) {
            this.newPasswordErrors = [this.$t('accountErrorMsg5')];
            return Promise.reject(new FormError());
          } else {
            this.newPasswordErrors = [];
            return Promise.resolve();
          }
        }).then(async () => {
          return this.save().then((resp: any) => {
            if (CommonUtils.isTrue(resp.data.invalidPassword)) {
              this.passwordErrors = [this.$t('schoolYearErrorMsg17')];
              return Promise.reject(new FormError());
            } else {
              this.passwordErrors = [];
              return this.reloadSettings();
            }
          });
        }).then(() => {
          this.confirmPassword = '';
          this.$refs.securityForm.resetValidation();
          this.updatedSettings = {};
        }).catch((error) => {
          if (!(error instanceof FormError)) {
            return Promise.reject(error);
          }
        }).finally(CommonUtils.hideLoading);
      }
    }

    async saveGoogle() {
      CommonUtils.showLoading();
      return this.save().then(() => {
        return this.reloadSettings();
      }).then(() => {
        this.resetPlans();
      }).finally(CommonUtils.hideLoading);
    }

    async saveSettings() {
      CommonUtils.showLoading();
      return this.save().then(() => {
        return this.reloadSettings();
      }).finally(CommonUtils.hideLoading);
    }

    removePhoto() {
      this.localPhotoUrl = '';
      this.selectedFile = null;
    }

    choosePhoto() {
      this.isChoosingPhoto = true;
    }

    applyPhoto() {
      this.photoUrl = this.localPhotoUrl;
      this.isChoosingPhoto = false;
    }

    defaultPhotoUrls(index: any) {
      return 'https://cdn.planbook.com/images/teacheravatars/teacher-' + ('' + index).padStart(2, '0') + '.png';
    }

    selectDefault(index: number) {
      this.localPhotoUrl = this.defaultPhotoUrls(index);
    }

    addGoogleEmail(value: string) {
      if (!this.googleEmail.includes(value)) {
        const items: Array<string> = ld.cloneDeep(this.googleEmail);
        items.push(value);
        this.googleEmail = items;
      }
    }

    get canUpdateSubscription() {
      return (!this.$currentUser.isAdmin || this.getSchoolSettings.primarySchool.paidByDistrict !== 'Y' || this.$currentUser.isDistrictAdmin);
    }

    renew(): void {
      if (['M', 'A'].includes(this.userMode)) {
        this.$refs.schoolSubscriptionRenewal.renew();
      } else {
        this.$refs.subscriptionRenewal.renew();
      }
    }

    addAccounts() {
      this.$refs.addSchoolAccounts.addAccounts();
    }

    getPaymentLabel(item: any) {
      let label = '';
      label += item.paymentId;
      if (CommonUtils.hasText(item.checkNum)) {
        label += ' - ' + item.checkNum;
      }
      return label;
    }

    removeGoogleEmail(value: string) {
      this.googleEmail = this.googleEmail.filter(e => e !== value);
    }

    selectImage() {
      this.selectingFile = true;
      window.addEventListener('focus', () => {
        this.selectingFile = false;
      }, { once: true });
      this.$refs.fileInput.click();
    }

    onFileChanged(e: Event) {
      const fileInput: any = e.target;
      this.selectedFile = fileInput.files[0];
      if (this.selectedFile) {
        this.localPhotoUrl = URL.createObjectURL(this.selectedFile);
      } else {
        this.localPhotoUrl = '';
        this.selectedFile = null;
      }
    }

    getBooleanValue(key: string): boolean {
      let value = this.updatedSettings[key];
      if (value === undefined || value === null) {
        value = this.userInfo.accountSettings[key];
        if (value === undefined || value === null) {
          value = this.userInfo.otherSettings[key];
        }
      }
      return this.isTrue(value);
    }

    setValue(key: string, value: any): void {
      this.updatedSettings = ld.set(ld.cloneDeep(this.updatedSettings), key, value);
    }

    getValue(key: string, defaultValue: any) {
      if (Object.prototype.hasOwnProperty.call(this.updatedSettings, key)) {
        return this.updatedSettings[key];
      }
      return defaultValue;
    }

    getNumber(key: string, defaultValue: any) {
      if (Object.prototype.hasOwnProperty.call(this.updatedSettings, key)) {
        return +this.updatedSettings[key];
      }
      return +defaultValue;
    }

    isTrue(value: any): boolean {
      return value === true || value === 'true' || value === 1 || value === 'Y' || value === 'y';
    }

    onCheckedChanged(item: any) {
      const email = item.email;
      if (!item.selected) {
        if (email === 'allcalendars@planbook.com') {
          this.googleEmail = this.calendars.map(c => c.email);
        } else {
          this.addGoogleEmail(email);
        }
      } else {
        if (email === 'allcalendars@planbook.com') {
          if (this.googleEmail.length === this.calendars.length) {
            this.googleEmail = [];
          }
        } else {
          this.removeGoogleEmail(email);
        }
      }
    }

    onClassroomCheckedChanged(item: any) {
      const id = item.id;
      const newValue = !item.selected;
      if (id === '0') {
        this.classItems.forEach(c => {
          c.selected = newValue;
        });
      } else {
        this.classItems.find(c => c.id === id).selected = newValue;
      }
      const selectedItems = this.classItems.filter(c => c.id !== '0' && c.selected);
      if (selectedItems.length === this.classItems.length - 1) {
        this.classItems[0].selected = true;
      } else {
        this.classItems[0].selected = false;
      }
      this.setValue('enableGoogleClasses', JSON.stringify(selectedItems));
    }

    onEmailNotificationsChanged() {
      const newValue = !this.allEmailNotifications;
      this.EMAIL_NOTIFICATIONS.forEach(e => {
        this.setValue(e, newValue);
      });
    }

    onEventNotificationsChanged() {
      const newValue = !this.allEventNotifications;
      EVENT_NOTIFICATIONS.forEach(e => {
        this.setValue(e, newValue);
      });
    }

    printInvoice(id: string) {
      CommonUtils.showLoading();
      this.print({
        printId: id,
        printType: this.userMode === 'T' ? 'T' : 'I',
        exportType: 'pdf'
      }).finally(CommonUtils.hideLoading)
    }

    async loadGoogleData(): Promise<any> {
      if (this.hasToken) {
        CommonUtils.showLoading();
        return this.loadCalendars().then(() => {
          return this.loadGoogleClasses();
        }).then(() => {
          const items = [];
          let count = 0;
          ld.cloneDeep(this.googleClasses).forEach(c => {
            if (c.selected === undefined && c.subjectId > 0) {
              c.selected = true;
              count++;
            }
            items.push(c);
          });
          items.unshift({
            id: '0',
            color: this.$vuetify.theme.currentTheme.primary,
            name: this.$t('allClassesLabel'),
            selected: count > 0 && count === this.googleClasses.length
          });
          this.classItems = items;
          Promise.resolve();
        }).finally(() => {
          CommonUtils.hideLoading();
        });
      } else {
        CommonUtils.hideLoading();
      }
      return Promise.resolve();
    }

    copyToClipboard(id: string) {
      navigator.clipboard.writeText(id);
    }

    @Watch('tab')
    onTabChange() {
      this.updatedSettings = {};
      this.confirmPassword = '';
      this.passwordErrors = [];
      this.newPasswordErrors = [];
      this.emailErrors = [];
    }

    @Watch('isChoosingPhoto')
    onChoosingPhoto() {
      if (this.isChoosingPhoto) {
        this.localPhotoUrl = this.photoUrl;
      } else {
        this.localPhotoUrl = '';
      }
    }

    doInit() {
      this.updatedSettings = {};
      this.confirmPassword = '';
      if (this.userMode === 'T') this.loadGoogleData();
    }

    created() {
      this.doInit();
      this.$nextTick(() => {
        this.$eventBus.$on('userModeChanged', this.doInit);
      })
    }

    destroyed() {
      this.$eventBus.$off('userModeChanged', this.doInit);
    }
}
