var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "px-10" },
    [
      _vm.pushDevicesEnabled
        ? _c(
            "v-card",
            { staticClass: "mt-2", attrs: { outlined: "" } },
            [
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c("span", { staticClass: "title-on-border" }, [
                    _vm._v(_vm._s(_vm.$t("NotificationDevicesLabel"))),
                  ]),
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.headersPush,
                      items: _vm.notificationDevicesPush,
                      "hide-default-footer": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: `header.action`,
                          fn: function ({ header }) {
                            return [
                              _c(
                                "th",
                                { on: { click: _vm.removeAllDevices } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        small: "",
                                        text: "",
                                        color: "error",
                                      },
                                    },
                                    [_vm._v(_vm._s(header.text))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.deviceName`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "td",
                                {
                                  on: {
                                    mouseover: function ($event) {
                                      return _vm.mouseOverItem(item)
                                    },
                                    mouseleave: _vm.mouseLeaveItem,
                                  },
                                },
                                [
                                  item.edit
                                    ? _c("v-text-field", {
                                        attrs: {
                                          autofocus: "",
                                          dense: "",
                                          "hide-details": "",
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.renameDevice(item)
                                          },
                                          blur: function ($event) {
                                            return _vm.renameDevice(item)
                                          },
                                        },
                                        model: {
                                          value: item.deviceName,
                                          callback: function ($$v) {
                                            _vm.$set(item, "deviceName", $$v)
                                          },
                                          expression: "item.deviceName",
                                        },
                                      })
                                    : _c(
                                        "div",
                                        {
                                          on: {
                                            click: function ($event) {
                                              item.edit = true
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-inline-block pr-2",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.deviceName) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          item.hover
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "d-inline-block",
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { "x-small": "" },
                                                    },
                                                    [_vm._v("fal fa-pencil")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.lastUpdate`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "td",
                                {
                                  on: {
                                    mouseover: function ($event) {
                                      return _vm.mouseOverItem(item)
                                    },
                                    mouseleave: _vm.mouseLeaveItem,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatToDisplay(item.lastUpdate)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.action`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "td",
                                {
                                  on: {
                                    mouseover: function ($event) {
                                      return _vm.mouseOverItem(item)
                                    },
                                    mouseleave: _vm.mouseLeaveItem,
                                  },
                                },
                                [
                                  item.hover
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "mx-3",
                                          attrs: {
                                            small: "",
                                            text: "",
                                            color: "error",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeDevice(item)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("removeLabel")))]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.eventEmailEnabled
        ? _c(
            "v-card",
            { staticClass: "mt-8", attrs: { outlined: "" } },
            [
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c(
                    "span",
                    { staticClass: "title-on-border" },
                    [
                      _vm._v(_vm._s(_vm.$t("emailNotificationsLabel")) + " "),
                      _c(
                        "v-icon",
                        {
                          staticClass: "pl-1",
                          attrs: { size: "1.25em" },
                          on: { click: _vm.showEmailInfo },
                        },
                        [_vm._v("fal fa-circle-info")]
                      ),
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.headersEmail,
                      items: _vm.notificationDevicesEmail,
                      "hide-default-footer": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: `item.deviceName`,
                          fn: function ({}) {
                            return [
                              _c("td", [
                                _vm._v(" " + _vm._s(_vm.getEmailAddress) + " "),
                              ]),
                            ]
                          },
                        },
                        {
                          key: `item.action`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "td",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.timeZones,
                                      dense: "",
                                      "hide-details": "",
                                    },
                                    on: { change: _vm.updateTimeZone },
                                    model: {
                                      value: item.timeZone,
                                      callback: function ($$v) {
                                        _vm.$set(item, "timeZone", $$v)
                                      },
                                      expression: "item.timeZone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("pb-confirm", { ref: "confirm" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }