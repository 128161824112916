











































































import PaymentServices from '@/services/payment-services';
import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Debounce } from 'vue-debounce-decorator';
import { namespace } from 'vuex-class';
import PayInvoice from './PayInvoice.vue';

const settings = namespace('settings');
const prints = namespace('prints');

@Component({
  components: {
    PayInvoice
  }
})
export default class SchoolSubscriptionRenewal extends Vue {
  localResolve: any;
  localValue = false;
  message = '';
  accounts = '';
  purchaseOrder = '';
  expiration = '';
  mode = '';
  months = 0;
  subTotalAmount = 0;
  totalDiscountAmount = 0;
  schoolDiscount = 0;
  loading = false;
  paymentId = 0;
  amount = 0;
  invoice = 'N';
  typing = false;

  $refs!: {
    payInvoice: PayInvoice
  }

  get hasErrorMsg() {
    return CommonUtils.hasText(this.message);
  }

  get isNewPricing() {
    return CommonUtils.isNewPricing20240701();
  }

  get monthlyRate() {
    return CommonUtils.monthlyRate();
  }

  @settings.Getter('getSchoolId')
  schoolId!: number;

  @settings.Mutation
  setPaidThroughDate!: (params?: any) => void;

  @prints.Action
  print!: (params: any) => Promise<any>;

  @settings.Action
  loadAdminPayments!: () => Promise<any>;

  @Watch('expiration')
  onExpirationChanged() {
    this.init();
  }

  @Watch('accounts')
  @Debounce(500)
  onAccountChanged() {
    this.typing = false;
  }

  @Watch('localValue')
  onLocalValueChange() {
    if (this.localValue) {
      this.reset();
      this.init();
    }
  }

  @Watch('typing')
  onTypingChange() {
    if (!this.typing) {
      this.init();
    }
  }

  async init(): Promise<any> {
    if (!this.loading) {
      this.loading = true;
      return PaymentServices.calculateAdd({
        apiKey: '2e6c6353-3891-4e8a-b579-f6ef074dea34',
        schoolId: this.schoolId,
        generateInvoice: this.invoice || 'N',
        accounts: this.accounts,
        purchaseOrder: this.purchaseOrder,
        expiration: this.expiration
      }).then((resp: any) => {
        this.setData(resp);
      }).finally(() => {
        this.invoice = 'N';
        this.loading = false;
      });
    }
  }

  setData(resp: any): void {
    const data = resp.data;
    this.accounts = data.numTeachers + '';
    this.expiration = data.paidThroughDate;
    this.months = data.numMonths;
    this.schoolDiscount = data.schoolDiscount;
    this.subTotalAmount = data.subTotalAmount;
    this.totalDiscountAmount = data.totalDiscountAmount;
    this.amount = data.paymentAmount;
    this.paymentId = data.paymentId;
  }

  addAccounts() {
    this.localValue = true;
    return new Promise((resolve) => {
      this.localResolve = resolve;
    });
  }

  payInvoice(invoice: any) {
    this.$refs.payInvoice.pay(invoice);
  }

  doRenew(mode: string): void {
    CommonUtils.showLoading();
    if (CommonUtils.hasNoText(this.accounts) || CommonUtils.hasNoText(this.expiration)) {
      return;
    }
    this.mode = mode;
    Promise.resolve().then(() => {
      if (mode !== 'quote') {
        this.invoice = 'Y';
        return this.init();
      } else {
        Promise.resolve();
      }
    }).then(async () => {
      this.localValue = false;
      this.setPaidThroughDate(this.expiration);
      if (this.mode !== 'quote') {
        return this.loadAdminPayments().then(() => {
          if (this.mode === 'pay' && this.paymentId > 0) {
            return this.payInvoice({
              paymentId: this.paymentId,
              numTeachers: this.accounts,
              paidThroughDate: this.expiration,
              paymentAmount: this.amount
            })
          } else {
            // Subscription extend to ' + this.paidThroughDate + '.'
            return Promise.resolve();
          }
        })
      } else {
        return this.print({
          printId: this.schoolId,
          printType: 'Q',
          quoteTeachers: this.accounts,
          quoteMonths: this.months,
          exportType: 'pdf'
        });
      }
    }).then(() => {
      this.localResolve();
    }).finally(CommonUtils.hideLoading);
  }

  get displaySubTotal() {
    return this.formatAmount(this.subTotalAmount);
  }

  get displayTotalDiscount() {
    return this.formatAmount(this.totalDiscountAmount);
  }

  get displayAmount() {
    return this.formatAmount(this.amount);
  }

  formatAmount(amount: number) {
    if (amount) {
      return amount.toFixed(2);
    }
    return '';
  }

  reset() {
    this.message = '';
    this.accounts = '';
    this.expiration = '';
    this.mode = '';
    this.months = 0;
    this.subTotalAmount = 0;
    this.totalDiscountAmount = 0;
    this.schoolDiscount = 0;
    this.loading = false;
    this.paymentId = 0;
    this.amount = 0;
    this.invoice = 'N';
    this.typing = false;
  }

  mounted() {
    this.reset();
    this.init();
  }
}
